import React from "react";
import { Link, graphql } from "gatsby";
import StatBox from "../../components/stat-box";
import PagePhoto from "../../components/page-photo";
import CareerLinkBox from "../../components/career-link-box";
import HeroPage from "../../components/hero-page";
import AddressJefferson from "../../components/address-jefferson";
import StatBoxes from "../../components/stat-boxes";
import ServiceList from "../../components/service-list";
import { postSecondary } from "../../data/post-secondary";
import { education } from "../../data/education";
import { hospitals } from "../../data/hospitals";
import { chambers } from "../../data/chambers";
import { airports } from "../../data/airports";
import ExplorePhotos from "../../components/explore-photos";
import Seo from "../../components/seo";

const Jefferson = ({ data }) => {
  const photos = data.allMdx.nodes;

  return (
    <>
      <Seo
        title="Jefferson County, North Central Pennsylvania"
        description="Jefferson County's natural resources include coal, natural gas, and timber reserves that are the envy of other regions.  Metal manufacturing is prominent in the County, and access to global markets is facilitated by a modern network of highways, railroads, and air transportation."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Jefferson County"
        caption="Clear Creek in Jefferson County"
        position="25%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>
              A great place to live, work, raise a family, and enjoy the
              outdoors
            </h2>

            <p>
              Home to hard working, generous, neighborly people that look out
              for one another.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Jefferson County's natural resources include coal, natural gas,
              and timber reserves that are the envy of other regions.
            </p>

            <p>
              Metal manufacturing is prominent in the County, and access to
              global markets is facilitated by a modern network of highways,
              railroads, and air transportation.
            </p>

            <p>
              Jefferson County features masterful architecture and character in
              its historic downtown areas as well as unique and diverse parks
              for all ages to enjoy.
            </p>

            <p>
              <i>Come for Fun, stay for Good!</i>
            </p>
          </div>
        </section>

        <StatBoxes>
          <StatBox number="44,084" text="Total population" />
          <StatBox number="44,136" text="Per capita income" />
          <StatBox number="Punxsutawney" text="Largest city" />
        </StatBoxes>
      </main>

      <div className="county-employers">
        <section>
          <div className="employers-table-container">
            <h2>Top employers in Jefferson County</h2>

            <table className="employers">
              <tr>
                <th>Employer</th>
                <th>Industry</th>
              </tr>

              <tr>
                <td>Beverage Air Corp</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Guardian Home & Community Services</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Owens-Brockway Glass Container</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Punxsutawney Area Hospital Inc</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Punxsutawney Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Miller Welding & Machine Co</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Brookville Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>State Government</td>
                <td></td>
              </tr>

              <tr>
                <td>Goodwill Industries of North Central PA</td>
                <td>Retail</td>
              </tr>

              <tr>
                <td>Brookville Equipment Corporation</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>
            </table>
          </div>

          <div className="employers-photos">
            <PagePhoto
              image={data.employersImage1.sharp.image}
              caption="Miller Fabrication Solutions, Jefferson County"
            />
            <PagePhoto
              image={data.employersImage2.sharp.image}
              caption="Blackbird Distillery, Jefferson County"
            />
          </div>
        </section>
      </div>

      <main>
        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Miller Fabrication Solutions, Jefferson County"
          address=<AddressJefferson />
        />

        <section>
          <h2>County Services</h2>

          <ServiceList
            title="Education"
            image={data.educationImage.sharp.fluid}
            position="40%"
            county="Jefferson"
            data={education}
            cols={2}
            headingText="School"
          />

          <ServiceList
            title="Post Secondary Education"
            image={data.postSecondary.sharp.fluid}
            position="42%"
            county="Elk"
            data={postSecondary}
            cols={2}
            headingText="School"
          />

          <div className="services-body">
            <div className="services-col">
              <ServiceList
                title="Hospitals"
                image={data.medicalImage.sharp.fluid}
                position="30%"
                county="Jefferson"
                data={hospitals}
                headingText="Facility"
              />
            </div>
            <div className="services-col">
              <ServiceList
                title="Chambers of Commerce"
                image={data.businessImage.sharp.fluid}
                position="55%"
                county="Jefferson"
                data={chambers}
                headingText="Service"
              />
            </div>
          </div>

          <ServiceList
            title="Airports"
            image={data.airportImage.sharp.fluid}
            position="20%"
            county="Jefferson"
            data={airports}
            headingText="Airport"
            cols={2}
          />
        </section>

        <ExplorePhotos photos={photos} county="Jefferson" />
      </main>
    </>
  );
};

export const query = graphql`
  query {
    educationImage: file(
      relativePath: { eq: "neonbrand-zFSo6bnZJTw-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    postSecondary: file(
      relativePath: { eq: "clay-banks-GX8KBbVmC6c-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(
      relativePath: {
        eq: "752202069710177517_kyle_yates-appreciation_of_beauty-clear_creek_jeffersonco.jpg"
      }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "752202069710177517_kyle_yates-appreciation_of_beauty-clear_creek_jeffersonco.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

    employersImage1: file(relativePath: { eq: "CRP7839.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage2: file(relativePath: { eq: "Blackbird-Moonshine-1.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    careerImage: file(relativePath: { eq: "careerlink-photo-jefferson.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    medicalImage: file(
      relativePath: { eq: "olga-guryanova-tMFeatBSS4s-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    businessImage: file(
      relativePath: { eq: "arlington-research-nFLmPAf9dVc-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    airportImage: file(relativePath: { eq: "dubois-airport.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    # slideshow photos
    allMdx(filter: { frontmatter: { county: { eq: "jefferson" } } }) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

export default Jefferson;
