import React from "react";

const AddressJefferson = () => {
  return (
    <>
      <h3>
        Jefferson County PA CareerLink<sup>&reg;</sup>
      </h3>
      <div>
        <p>
          103 East Union Street, Suite 2
          <br />
          Punxsutawney, PA 15767
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
    </>
  );
};

export default AddressJefferson;
